<template>
  <v-container id="training-plan-evolution" fluid tag="section" class="container-full-size">
    <v-col cols="12">
      <v-btn color="default" class="mr-0" @click="back()">
        {{ str['back'] }}
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="data"
        :search="search"
        :no-data-text="str['no_data']"
        :no-results-text="str['no_data']"
        class="elevation-1"
        @click:row="openItem"
      >
        <template #top>
          <v-toolbar flat>
            <v-text-field v-model="search" append-icon="mdi-magnify" :label="str['search']" single-line hide-details />
            <v-spacer />
          </v-toolbar>
        </template>
        <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
        </template>
      </v-data-table>
    </v-col>

    <v-dialog v-model="dialogItem" persistent max-width="750px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ itemOpened ? itemOpened.name : '' }}
          </span>
        </v-card-title>

        <v-card-text>
          <v-col>
            <div style="display: flex; justify-content: flex-end">
              <div style="width: 100%; max-width: 200px">
                <v-menu
                  v-model="datepickerMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field v-model="filterDatesText" :label="str['interval_dates']" readonly v-bind="attrs" hide-details v-on="on" />
                  </template>
                  <v-date-picker v-model="filterDates" range style="margin: 0" :locale="datepickerLanguage" @change="applyDatesFilter" />
                </v-menu>
              </div>
            </div>
          </v-col>
          <v-col>
            <v-tabs v-model="tabActive" background-color="transparent" color="secondary" style="margin-top: 0" grow>
              <v-tab v-for="tab in tabs" :key="tab.id">
                {{ tab.name }}
              </v-tab>
            </v-tabs>
          </v-col>
          <v-tabs-items v-model="tabActive">
            <v-tab-item v-for="tab in tabs" :key="tab.id">
              <div v-if="tabs[tabActive].id === 'historic'">
                <v-data-table
                  :headers="historicHeaders"
                  :items="historicData"
                  sort-by="date"
                  :no-data-text="str['no_data']"
                  :no-results-text="str['no_data']"
                  class="elevation-1"
                >
                  <template #item.date="{ item }">
                    {{ item.date }}
                    <v-icon
                      v-if="!showNotes && item.notes"
                      color="info"
                      dark
                      class="cursor-hover ml-1"
                      @click.stop="showInformation(item.notes)"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                    {{ pageStart }}-{{ pageStop }} {{ str['of'] }}
                    {{ itemsLength }}
                  </template>
                </v-data-table>
              </div>
              <div v-if="tabs[tabActive].id === 'evolution'">
                <v-col v-if="repsChart && !repsChart.labels.length" style="text-align: center; color: #888; padding-bottom: 20px">
                  {{ str['selected_date_range_no_data'] }}
                </v-col>
                <v-col v-if="repsChart && repsChart.labels.length">
                  <base-material-chart-card
                    :data="repsChart"
                    :options="repsChartOptions"
                    color="blue-grey darken-3"
                    hover-reveal
                    type="Line"
                    :shadow="true"
                  >
                    <h4 class="card-title font-weight-light mt-2 ml-2">
                      {{ str['progress_number_repetitions'] }}
                    </h4>
                  </base-material-chart-card>
                </v-col>
                <v-col v-if="loadChart && loadChart.labels.length" class="mt-5">
                  <base-material-chart-card
                    :data="loadChart"
                    :options="loadChartOptions"
                    color="blue-grey darken-3"
                    hover-reveal
                    type="Line"
                    :shadow="true"
                  >
                    <h4 class="card-title font-weight-light mt-2 ml-2">
                      {{ str['load_progress'] }}
                    </h4>
                  </base-material-chart-card>
                </v-col>
              </div>
              <div v-if="tabs[tabActive].id === 'notes'">
                <v-data-table
                  :headers="historicNotesHeaders"
                  :items="historicNotes"
                  sort-by="date"
                  :no-data-text="str['no_data']"
                  :no-results-text="str['no_data']"
                  class="elevation-1"
                >
                  <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                    {{ pageStart }}-{{ pageStop }} {{ str['of'] }}
                    {{ itemsLength }}
                  </template>
                </v-data-table>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="dialogItem = false">
            {{ str['close'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'

export default {
  data() {
    const user = Utils.getUser()
    const client = Utils.getStorage('client')
    const showNotes =
      user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_progress_notes

    const tabs = [
      {
        id: 'historic',
        name: window.strings['historic'],
      },
      {
        id: 'evolution',
        name: window.strings['evolution'],
      },
    ]
    if (showNotes) {
      tabs.push({
        id: 'notes',
        name: window.strings['notes'],
      })
    }

    const historicHeaders = []
    historicHeaders.push({
      text: window.strings['date'],
      value: 'date',
      align: 'left',
    })
    if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_progress_series) {
      historicHeaders.push({
        text: window.strings['serie'],
        value: 'set_id',
        align: 'center',
      })
    }
    historicHeaders.push({
      text: window.strings['load_kg'],
      value: 'load',
      align: 'center',
    })
    historicHeaders.push({
      text: window.strings['number_of_reps'],
      value: 'reps',
      align: 'center',
    })
    if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_rir) {
      historicHeaders.push({
        text: window.strings['rir'],
        value: 'rir',
        align: 'center',
      })
    }

    return {
      str: window.strings,
      datepickerLanguage: window.datepickerLanguage,
      user: user,
      client: client ? client : {},
      showNotes: showNotes,
      data: [],
      headers: [{ text: window.strings['exercise'], value: 'name', align: 'left' }],
      search: '',
      itemOpened: null,
      dialogItem: false,
      tabs: tabs,
      tabActive: 0,
      historicHeaders: historicHeaders,
      historicNotesHeaders: [
        {
          text: window.strings['date'],
          value: 'date',
          align: 'left',
          width: 150,
        },
        { text: window.strings['notes'], value: 'notes', align: 'left' },
      ],
      historicData: [],
      historicNotes: [],
      datepickerMenu: false,
      filterDates: [Utils.getFormatDate(new Date(Date.now() - 2592000000)), Utils.getFormatDate()],
      repsChartOptions: {
        lineSmooth: this.$chartist.Interpolation.cardinal({
          tension: 0,
        }),
        low: 0,
        high: 50,
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        plugins: [
          this.$chartist.plugins.tooltip({
            appendToBody: true,
          }),
        ],
      },
      loadChartOptions: {
        lineSmooth: this.$chartist.Interpolation.cardinal({
          tension: 0,
        }),
        low: 0,
        high: 50,
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        plugins: [
          this.$chartist.plugins.tooltip({
            appendToBody: true,
          }),
        ],
      },
      loadChart: null,
      repsChart: null,
    }
  },
  computed: {
    filterDatesText() {
      return this.filterDates.join(' / ')
    },
  },
  watch: {
    dialogItem(val) {
      const dialog = document.getElementsByClassName('v-dialog')
      if (dialog && dialog[0]) {
        dialog[0].scrollTo(0, 0)
      }
      val || this.closeItem()
    },
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.getData()
  },
  methods: {
    back: function () {
      this.$router.goBack()
    },
    getData: function () {
      const self = this
      this.$isLoading(true)
      Api.getTrainingPlanProgressExercises(this.client.dbId, function (response) {
        self.$isLoading(false)
        if (response.success) {
          self.data = response.data.filter(function (item) {
            return item && item.id
          })
        } else {
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
    openItem(item) {
      this.loadChart = null
      this.repsChart = null
      this.itemOpened = item
      this.dialogItem = true
      this.getWorkoutProgress()
    },
    closeItem() {
      this.loadChart = null
      this.repsChart = null
      this.itemOpened = null
      this.dialogItem = false
    },
    applyDatesFilter() {
      this.loadChart = null
      this.repsChart = null
      this.datepickerMenu = false
      this.getWorkoutProgress()
    },
    getWorkoutProgress() {
      const self = this
      const payload = {
        clientId: this.client.dbId,
        workoutId: this.itemOpened.id,
        startDate: this.filterDates[0],
        endDate: this.filterDates[1],
      }
      this.$isLoading(true)
      Api.getTrainingPlanProgressData(payload, function (response) {
        if (response.success) {
          self.updateCharts(response.data)
          self.getHistoricData(response.data)
        } else {
          self.$isLoading(false)
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
    updateCharts(data) {
      const self = this
      const labels = data.map(function (item, index) {
        return index.toString()
      })
      setTimeout(function () {
        self.repsChart = {
          labels: labels,
          series: [
            data.map(function (item) {
              return {
                meta:
                  Utils.convertUtcDate(item.date) +
                  '\n' +
                  window.strings['number_of_reps'] +
                  ': ' +
                  item.reps +
                  '\n' +
                  window.strings['load_kg'] +
                  ': ' +
                  item.load,
                value: item.reps,
              }
            }),
          ],
        }
        const repsMax = Math.max.apply(
          Math,
          data.map(function (o) {
            return o.reps
          }),
        )
        self.repsChartOptions.high = repsMax + 20

        self.loadChart = {
          labels: labels,
          series: [
            data.map(function (item) {
              return {
                meta:
                  Utils.convertUtcDate(item.date) +
                  '\n' +
                  window.strings['number_of_reps'] +
                  ': ' +
                  item.reps +
                  '\n' +
                  window.strings['load_kg'] +
                  ': ' +
                  item.load,
                value: item.load,
              }
            }),
          ],
        }
        const loadMax = Math.max.apply(
          Math,
          data.map(function (o) {
            return o.load
          }),
        )
        self.loadChartOptions.high = loadMax + 20

        self.$isLoading(false)
      }, 500)
    },
    getHistoricData(data) {
      const self = this
      const historicData = []
      const historicNotes = []
      data.forEach(function (item) {
        if (!self.showNotes || (self.showNotes && !item.notes)) {
          historicData.push({
            date: Utils.convertUtcDate(item.date),
            load: item.load,
            reps: item.reps,
            set_id: item.set_id,
            rir: item.rir,
            notes: item.notes,
          })
        } else {
          historicNotes.push({
            date: Utils.convertUtcDate(item.date),
            notes: item.notes,
          })
        }
      })
      this.historicData = historicData
      this.historicNotes = historicNotes
    },
    showInformation: function (info) {
      this.$alert(info, '', 'info', Utils.getAlertOptions())
    },
  },
}
</script>
